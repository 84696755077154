import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.isShowModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isShowModal) = $event)),
    title: _ctx.modalTitle,
    footerButtons: _ctx.footerButtons,
    formRules: _ctx.formRules,
    closeOnClickModal: true,
    width: "850px",
    top: "25vh",
    labelWidth: "140px"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "categoryName",
            size: "small",
            label: "分类名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "分类名称",
                modelValue: _ctx.formData.categoryName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.categoryName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "categoryDesc",
            size: "small",
            label: "分类描述"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "分类描述",
                modelValue: _ctx.formData.categoryDesc,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.categoryDesc) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "createdTime",
            size: "small",
            label: "新增日期"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "现在",
                modelValue: _ctx.formData.createdTime,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.createdTime) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "modifiedTime",
            size: "small",
            label: "修改日期"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "暂无",
                modelValue: _ctx.formData.modifiedTime,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.modifiedTime) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "title", "footerButtons", "formRules"]))
}