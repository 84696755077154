
  import { defineComponent, reactive, ref, onMounted } from '@vue/runtime-core'
  import { addOrUpdateGoodsCategory } from '@/api/goods'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Buttons } from '@/types/BasicComponent'
  import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'
  import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
  
  export default defineComponent({
  	components: {
  		BasicFormDialog,
  	},
    emits: ['reloadPageData'],
  	setup(props, context){
      const isShowModal = ref(false)
      const isAjaxing = ref(false)
      const modalTitle = ref("")
      const formRules = reactive<FormRulesMap>({
      	/* categoryName: [
      		{ required: true, message: '请输入分类名称' },
      	],
      	categoryDesc: [
      		{ required: true, message: '请输入分类描述' },
      	] */
      })
      const footerButtons = reactive<Buttons>([
      	{
      		label: '提交',
      		type: 'primary',
      		onClick: () => {
            if(isAjaxing.value){
              return !ElMessage.info('正在提交...')
            }
            
            isAjaxing.value = true
            addOrUpdateGoodsCategory(formData).then(() => {
              console.log('成功。。。')
              context.emit('reloadPageData', 1)
              ElMessage.success('已提交')
              isAjaxing.value = false
              isShowModal.value = false
            }).catch(() =>{
              console.log('失败！！！')
              isAjaxing.value = false
            })
      		}
      	}, 
      	{
      		label: '取消',
      		type: 'default',
      		onClick: () => (isShowModal.value = false)
      	}
      ])
      const formData = reactive<AnyObject>({
      	categoryName: 	  "",
      	categoryDesc: 		"",
        createdTime: 	    "",
      	modifiedTime: 		"",
      })
      const showAddeModal = (infos: AnyObject) => {
        if(infos && infos.categoryId){
          formData.categoryId         = infos.categoryId
          formData.categoryName       = infos.categoryName
          formData.categoryDesc       = infos.categoryDesc
          formData.createdTime        = infos.createdTime
          formData.modifiedTime       = infos.modifiedTime
          modalTitle.value = "修改分类"
        }else{
          formData.categoryId         = ""
          formData.categoryName       = ""
          formData.categoryDesc       = ""
          formData.createdTime        = ""
          formData.modifiedTime       = ""
          modalTitle.value = "添加分类"
        }
        
        isShowModal.value = true
        isAjaxing.value = false
      }
      
      return {
        isShowModal,
        modalTitle,
        footerButtons,
        formRules,
        formData,
        showAddeModal
      }
    }
  })
